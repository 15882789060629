@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  scroll-behavior: smooth;
  --fontFamily: "Poppins", sans-serif;
  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemiBold: 600;
  --fontWeightBold: 700;
  --fontWeightExtraBold: 800;
  --fontWeightBlack: 900;
  --fontStyleNormal: normal;
  --fontStyleItalic: italic;
  --blackColor: #1C1C1E;
  --darkGrayColor: #a9a9a9;
  --darkBlackColor: #161616;
  --whiteColor: #FFFFFF;
  --darkBlueColor: #73C2FBB2;
  --lightBlueColor: #e3f3fe;
  --layoutTheme: #191919;
  --layoutSiderMenu: #23211e;
  --tomatoRed: #ff4444;

  --defaultRedcolor: #f62e27;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/* Side Scroller */
::-webkit-scrollbar {
  height: 9px;
  width: 8px
}

::-webkit-scrollbar-track {
  background: #d9dcd7
}

::-webkit-scrollbar-thumb {
  background: var(--darkBlueColor);
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #337ec3
}

/* =========================================================== ANTD GLOBAL =========================================================== */
:where(.css-dev-only-do-not-override-1k979oh).ant-layout {
  font-family: var(--fontFamily) !important;
  font-weight: 600;
}
.ant-upload-list.ant-upload-list-text {
  display: none !important;
}
header.ant-layout-header.css-dev-only-do-not-override-1rqnfsa {
  background: var(--whiteColor);
}

li.ant-menu-item.ant-menu-item-only-child {
  color: var(--blackColor) !important;
  background: var(--lightBlueColor);
  height: 3rem;
  margin: 2px 0px !important;
}

li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  color: var(--whiteColor) !important;
  border-left: 5px solid #73C2FB;
  background: var(--darkBlueColor);
  font-weight: bold;
}

.ant-layout .ant-layout-sider {
  background: var(--whiteColor);
}

.ant-menu-inline .ant-menu-item {
  height: 40px;
  line-height: 25px;
  list-style-position: inside;
  list-style-type: disc;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-menu .ant-menu-title-content {
  transition: color 0.3s;
  align-items: center;
  display: flex;
}

.ant-layout-sider-trigger {
  display: none;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  background-color: var(--darkBlueColor);
  border: 1px dashed var(--lightBlueColor);
}

.ant-table-thead .ant-table-cell {
  background-color: var(--darkBlueColor) !important;
  color: var(--lightBlueColor) !important;
}

.jodit-container:not(.jodit_inline) .jodit-workplace,
.jodit-container:not(.jodit_inline) {
  border-radius: 3px;
}

.jodit-container:not(.jodit_inline) {
  border: 1px solid var(--darkBlueColor) !important;
  border-radius: 3px;
}

.jodit-status-bar {
  display: none !important;
}

.ant-input-outlined {
  border-width: 1px;
  border-style: solid;
  border-color: var(--darkBlueColor);
}

.ant-input::placeholder {
  color: lightgray !important;
}

.ant-input-outlined,
.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):hover {
  border-width: 1px;
  border-style: solid;
  border-color: var(--darkBlueColor);
}


span.ant-select-selection-item {
  display: flex;
  text-align: center;
  align-items: center;
  /* color: var(--whiteColor); */
}

.ant-input-outlined:hover {
  border-color: var(--darkBlueColor);
  background-color: #ffffff;
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  border-width: 1px;
  border-style: solid;
  border-color: #ff4d4f;
}

.ant-picker-outlined.ant-picker-status-error:not(.ant-picker-disabled) {
  border-width: 1px;
  border-style: solid;
  border-color: #ff4d4f;
}

.ant-input-affix-wrapper>input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
}

.ant-picker-outlined {
  border-width: 1px;
  border-style: solid;
  border-color: var(--darkBlueColor);
}

.ant-select-selector {
  /* border: 1px solid var(--darkBlueColor) !important; */
  min-height: 2.5rem;
}

.ant-select-single {
  min-height: 2.5rem;
  text-align: left;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  width: 8rem !important;
  height: 8rem !important;
  margin-inline-end: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  border: 1px dashed var(--darkBlueColor);
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item {
  width: 8rem !important;
  height: 8rem !important;
}

input {
  min-height: 2.5rem;
}

.ant-switch {
  background-color: var(--darkGrayColor) !important;
}

.ant-switch.ant-switch-checked {
  background: var(--darkBlueColor) !important;
}

.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  color: var(--whiteColor);
  background-color: var(--darkBlueColor);
  border-color: var(--darkBlueColor);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}


.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding-inline: 15px;
  padding-block: 0;
  font-size: 14px;
  line-height: 30px;
  background: transparent !important;
  border: transparent !important;
}

/* Table */
.ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper .ant-table-thead>tr>td {
  border-bottom: none !important;
}

.ant-table-wrapper .ant-table {
  background: transparent;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

/* Chart */
text.highcharts-credits {
  display: none;
}

/* Jodit Editor */
.jodit-toolbar__box:not(:empty) {
  border-bottom: transparent !important;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

g.highcharts-legend-item.highcharts-area-series.highcharts-color-undefined.highcharts-series-0 {
  display: none;
}

g.highcharts-legend-item.highcharts-map-series.highcharts-color-undefined.highcharts-series-0 {
  display: none;
}

.ant-layout .ant-layout-header {
  background-color: var(--whiteColor);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

form {
  width: 75%;
}

/* =========================================================== LOCALE VARIABLES =========================================================== */

.searchField>input {
  background-color: transparent !important;
  outline: none;
  border: none;
  padding-inline: 1rem;
}

.lightBlackBg {
  background-color: var(--whiteColor);
  padding: 1rem;
}

.fontWeightBold {
  font-weight: var(--fontWeightBold) !important;
}

.reactIcons {
  color: var(--darkBlueColor);
  font-size: 3rem;
}

a {
  color: inherit;
  text-decoration: none !important;
}

.commonButton {
  background-color: var(--darkBlueColor);
  min-height: 2.8rem;
  min-width: 8rem;
  font-weight: var(--fontWeightBold);
  color: var(--whiteColor);
}

.inputFormShadow {
  box-shadow: 0px 4px 14px 0px #73C2FB40;

}

.italicText {
  font-style: var(--fontStyleItalic);
}

.centerContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp_container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem 0 0 0;
}

.otp_input_container {
  margin-top: 0rem !important;
  margin: 0.8rem;
  padding: 1rem;
  width: 4rem !important;
  height: 4rem !important;
  font-size: 2rem;
  border: none !important;
  border-color: none !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0.2rem;
}

.commonBoxShadow {
  box-shadow: 0px 4px 15px 0px #00000026;
}

.customSiderWidth {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important;
  flex: 0 0 250px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.br10 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.bgMedia {
  background: url('./Assets/bgMedia.png');
  min-height: 100vh;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100vw;
  background-size: cover;
}